import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import AutoComplete from "react-google-autocomplete"
import { RouletteSpinnerOverlay } from 'react-spinner-overlay'
import { datadogRum } from "@datadog/browser-rum";

enum States {
    NSW = "NSW",
    VIC = "VIC",
    QLD = "QLD",
    SA = "SA",
    WA = "WA",
    TAS = "TAS",
    NT = "NT",
    ACT = "ACT"
}

enum EmploymentStatus {
    FullTime = "Full Time",
    PartTime = "Part Time",
    Casual = "Casual",
    Contract = "Contract",
    SelfEmployed = "Self Employed",
    Unemployed = "Unemployed",
}

enum EmploymentType {
    Employee = "Employee",
    BusinessOwner = "Business Owner Or Self Employed",
    Unemployed = "Unemployed", 
}

enum BusinessStructure {
    SoleTrader = "Sole Trader",
    Partnership = "Partnership",
    Company = "Company",
    Trust = "Trust",
}

enum LoanPurpose {
    CarLoan = "Car Loan",
    Motorcycle = "Motorcycle Loan",
    Caravan = "Caravan Loan",
    Boat = "Boat Loan",
    PersonalLoanSec = "Personal Loan (Secured)",
    PersonalLoanUnsec = "Personal Loan (Unsecured)",
    HorseFloat = "Horse Float Loan",
    Tractor = "Tractor Loan",
    OtherAsset = "Other Asset (Tractor, Trailer etc.)",
}

type Inputs = {
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
    age: number;
    driversLicenceNumber: string;
    licenceIssuingState: States;
    phoneNumber: string;
    email: string;
    livingSituation: string;
    residentialStatus: string;
    address: string;
    atAddressYears: number | string;
    atAddressMonths: number;
    postcode: string;
    suburb: string;
    state: States;
    timeAtAddressYears: number;
    timeAtAddressMonths: number;
    employmentStatus: EmploymentStatus;
    employmentType: EmploymentType;
    occupation: string;
    employerName: string;
    atJobYears: number;
    atJobMonths: number;
    incomeFrequency: string;
    incomeAfterTax: number;
    afterTaxSalaryYearly: number;
    loanPurpose: LoanPurpose;
    loanAmount: number;
    loanTermMonths: number;
    detailsOfEnquiry: string;
    acceptTerms: boolean;
    currentlyLiving: boolean;
    currentlyWorking: boolean;
    businessName: string;
    businessStructure: BusinessStructure;
    abnRegistrationPeriod: string;
    registeredGST: boolean;

}

type Vehicle = {
    StockNumber: string;
    Make: string;
    Model: string;
    Year: number;
    Odometer: number;
    FuelType: string;
    WOVR: string;
    VIN: string;
    FixedPrice: number;
    BuildDate: string;
}

declare const window: Window & { dataLayer: Record<string, unknown>[]; };

const ExpressForm = () => {
    // const [value, setValue] = useState<Value>(new Date());
    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const [paginationStart, setPaginationStart] = useState<number>(0);
    const vehicleId = new URLSearchParams(window.location.search).get("id");
    const referrer = new URLSearchParams(window.location.search).get("url");
    const [gstRegistered, setGstRegistered] = useState<boolean>();
    const [hasClickedGST, setHasClickedGST] = useState<boolean>(false);
    const [hasClickedSubmit, setHasClickedSubmit] = useState<boolean>(false);
    const [state, setState] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [postcode, setPostcode] = useState<string>("");
    const [subPremise, setSubPremise] = useState<string>("");
    const [streetNumber, setStreetNumber] = useState<string>("");
    const [streetName, setStreetName] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false); 
    const [formSubmittedError, setFormSubmittedError] = useState<boolean>(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
    const { register, handleSubmit, control, watch, setFocus, formState: { errors } } = useForm<Inputs>({defaultValues: {acceptTerms: false}} );
    const methods = useForm<Inputs>();
    // ** Personal Watchers
    const firstName = watch("firstName");
    const lastName = watch("lastName");
    const email = watch("email");
    const phoneNumber = watch("phoneNumber");
    const livingSituation = watch("livingSituation");
    const address = watch("address");
    const atAddressMonths = watch("atAddressMonths");
    const residentialStatus = watch("residentialStatus");
    const employmentTypeCheck = watch("employmentType");
    // ** Employment Watchers
    const employmentStatus = watch("employmentStatus");
    const atAddressYears = watch("atAddressYears");
    const atJobMonths = watch("atJobMonths");
    const atJobYears = watch("atJobYears");
    const incomeAfterTax = watch("incomeAfterTax");
    const employerName = watch("employerName");
    const occupation = watch("occupation");
    // ** Business Watchers
    const businessStructure = watch("businessStructure");
    const businessName = watch("businessName");
    const abnRegistrationPeriod = watch("abnRegistrationPeriod");


    


    function isTouched(inputField: any){
        return Boolean(inputField);
    }    

    useEffect(() => {    

        
        // ** Query the Grays api for details   
         

        // ** Get vehicle Details if ID is parsed
        if(vehicleId != null) {
        
        if(vehicle === null && vehicleId != null) {
            
            // queryAPI()
        }      
        }
        if(formSubmittedError) {
            window.scrollTo(0, 0)
        setTimeout(() => {
            setFormSubmittedError(false);
        }, 5000);
        }

        if (Object.keys(errors).length > 0) {
        const firstError = Object.keys(errors)[0] as keyof typeof errors;
        console.log(firstError as string)
        setFocus(firstError as any);
        }

    }, [paginationStart, employmentTypeCheck, errors, setFocus, formSubmittedError, subPremise, streetName, streetNumber, city, postcode, state, country ]);

    const handleGSTClick = (e: any) => {
    e.preventDefault();
    setHasClickedGST(true);
    if(e.target.id === "GSTRegisteredYes"){
        setGstRegistered(true);
    } else {
        setGstRegistered(false);
    }
    
};
    const onError = (errors: any, e: any) => {
        setHasClickedSubmit(true);
    }

    const onSubmit: SubmitHandler<Inputs> = data => {
        setHasClickedSubmit(true);
        window.dataLayer.push({
        'event': 'formSubmitted'
        });
        const theVehicle = {
            stockNumber: vehicleId,
            make: vehicle?.Make,
            model: vehicle?.Model,
            year: vehicle?.Year,
            odometer: vehicle?.Odometer,
            fuelType: vehicle?.FuelType,
            WOVR: vehicle?.WOVR,
            vin: vehicle?.VIN,
            fixedPrice: vehicle?.FixedPrice,
            buildDate: vehicle?.BuildDate
        }
        
        const businessData = {
            "businessName": data.businessName,
            "businessStructure": data.businessStructure,
            "aBNRegisteredYearsRange": data.abnRegistrationPeriod,
            "isRegisteredForGST": gstRegistered
        }

        const employmentData = {
            
                "status": data.employmentStatus || null,
                "atYears": data.atJobYears || null,
                "atAdditionalMonths": data.atJobMonths || null,
                "netIncome": data.incomeAfterTax || null,
                "employerName": data.employerName || null,
                "position": data.occupation || null,
                }
                
        

        const additionalInformation = {
            address: JSON.stringify(address)
        }
        

        const turboReferralBody = {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "mobile": data.phoneNumber,
            "email": data.email,
            "urgency": "ASAP",
            "loanType": "Car Loan",
            "saleType": "Dealer",
            "purchasePrice": theVehicle.fixedPrice || 0,
            "certaintyStage": "Exact",
            "requestedLoanTermYears": 5,
            "personalNetMonthlyIncome": data.incomeAfterTax || 0,
            
            "numberOfDependants": 0,
            "currentWorkStatus": data.employmentType,
            "residency": {
                "residencyStatus": data.residentialStatus,
                "livingSituation": data.livingSituation,
                "state": state,
                "atYears": data.atAddressYears,
                "atAdditionalMonths": data.atAddressMonths,
                "address": address,
                "addressLine1": subPremise !== "" ? `${subPremise}` : `${streetNumber} ${streetName}`,
                "addressLine2": subPremise !== "" ?`${streetNumber} ${streetName}` : " ",
                "city": city,
                "postcode": postcode,
                "country": country

            },
            "business": data.employmentType === "Business Owner Or Self Employed" ? businessData : {},
            
            "employments": 
                data.employmentType === "Employee" ? [employmentData] :[]
            ,
            "asset": {
                "use": data.employmentType === "Business Owner Or Self Employed" ? "Business" : "Personal",
                "condition": "Used",
                "buildYear": theVehicle.year || 2000,
                "description": vehicleId ? `${theVehicle.make} ${theVehicle.model} (${theVehicle.year}) - ${theVehicle.fuelType}` : 'No Vehicle Supplied',
                "vIN": theVehicle.vin || "No VIN",
            },
            "partnerLeadId": vehicleId || "No Lead ID",
            "additionalInformation": JSON.stringify(referrer) || "No PDP parsed"
            }

            setIsLoading(true)
            fetch("https://api-staging.carclarity.com.au/v1/turbo/referrals", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-PARTNER-ID": "bcuoptn6wkat7a28vse9hxuz9p3f5n43",
                    "X-API-KEY": "3MzhG4Erugb0ZpmcWJaLfXKBYO7dgSN2wGGWNtFBUsOUYW4ZhfLJKAgcC1NmZn98"
                },
                body: JSON.stringify(turboReferralBody)
            })
            .then(response => response.json())
            .then(data => {
                if(data.error) {
                    // ** Send error to GTM dataLayer
                    
                    window.dataLayer.push({
                    'event': 'formSubmitError',
                    'error': data.error
                    });
                    // ** Send error to datadog RUM
                    datadogRum.addError(data.error)
                    setIsLoading(false)
                    setApiErrorMessage(data.error)
                    setFormSubmittedError(true)
                }
                else {
                    
                    window.dataLayer.push({
                    'event': 'formSuccessfullySubmitted'
                    });
                    setFormSubmitted(true);
                    setIsLoading(false);
                }
                
            })
            .catch((error) => {
                setIsLoading(false)
                // ** Send error to GTM dataLayer
                window.dataLayer.push({
                    'event': 'formSubmitError',
                    'error': error
                    });
                // ** Send error to datadog RUM
                datadogRum.addError(error)
                setApiErrorMessage(error)
                setFormSubmittedError(true)
            })
    }

    const buttonActive = `text-graysPrimaryBlue border w-1/2 border-solid border-graysPrimaryBlue font-normal text-sm px-9 py-3 rounded-full`
    const buttonNotActive = `text-gray border w-1/2 border-grey-300 border-solid font-normal text-sm px-9 py-3 rounded-full`
    const buttonNotPressedError = `text-graysPrimaryBlue border w-1/2 border-solid border-graysErrorRed font-normal text-sm px-9 py-3 rounded-full`
    const sectionTitle = {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "19px",
        color: "#303030"
    }
    const label = {
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
        color: "#303030"
    }

    const inputNotTouched = {
        fontSize: "14px",
        borderColor: "#E8E8E8"
    }

    const inputTouched = {
        fontSize: "14px",
        borderColor: "#004E93"
    }

    const inputError = {
        fontSize: "14px",
        borderColor: "#E31111"
    }


    const selectInputNotTouched = {
        fontSize: "14px",
        borderColor: "#E8E8E8",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
        backgroundPosition: `right 15px center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `25px`,
        paddingRight: `15px`,
        alignItems: "center"
    }

    const selectInputTouched = {
        fontSize: "14px",
        borderColor: "#004E93",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
        backgroundPosition: `right 15px center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `25px`,
        paddingRight: `15px`,
        alignItems: "center"
    }

    const selectInputError = {
        fontSize: "14px",
        borderColor: "#E31111",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
        backgroundPosition: `right 15px center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `25px`,
        paddingRight: `15px`,
        alignItems: "center"
    }


    const addressInputNotTouched = {
        fontSize: "14px",
        borderColor: "#E8E8E8",
        padding: "8px"
    }

    const addressInputTouched = {
        borderColor: "#004E93",
        padding: "8px"
    }

    const addressInputError = {
        borderColor: "#E31111",
        padding: "8px"
    }

    const errorText = {
        fontWeight: "400",
        fontFamily: "Avenir, sans-serif",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#E31111"
    }
    const checkboxErrorText = {
        fontWeight: "400",
        fontFamily: "Avenir, sans-serif",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#E31111",
        paddingTop: "8px",
        marginTop: "8px"

    }

    function showApiErrorToast() {
        
        return (
                    <>
                    <div className="fixed flex justify-center items-center w-full p-4 top-5">
                        <div className="max-w-xs bg-white border-2 border-graysErrorRed rounded-md shadow-lg">
                            <div className="flex p-4">
                                <div className="ml-3">                                    
                                    <p className="text-sm text-gray-700">
                                        {apiErrorMessage}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )
    }


    function isEmploymentTypeEmployee()  {
        return (
            <>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="Employer Name">Employer name</label>
                <input id="employer" maxLength={200} style={isTouched(employerName) ? inputTouched : errors?.employerName?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="text" placeholder="" {...register("employerName", { required: "Please enter your employer name", maxLength: 200 })} />
                <ErrorMessage errors={errors} name="employerName" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="Employment Status">Employment status</label>
                <select required defaultValue={''} id="employmentStatus" style={isTouched(employmentStatus)? selectInputTouched : errors?.employmentStatus?.message ? selectInputError : selectInputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Employment Status" {...register("employmentStatus", { required: "Please select an option" })} >
                    <option hidden disabled value=''>Select</option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Casual">Casual</option>
                    <option value="Contract Role">Contract Role</option>
                </select>
                <ErrorMessage errors={errors} name="employmentStatus" render={({ message }) => <p style={errorText}>{message}</p>} />    
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="Position">Position</label>
                <input id="occupation" maxLength={40} style={isTouched(occupation) ? inputTouched : errors?.occupation?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="text" placeholder="" {...register("occupation", { required: "Please enter your position", maxLength: 40 })} />
                <ErrorMessage errors={errors} name="occupation" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="phoneNumber">Time at current employment</label>
                <div className="flex flex-row justify-between grow justify-items-stretch w-full" style={{gap:"16px"}}>
                
                <select required defaultValue={''} id="atJobYears" style={isTouched(atJobYears)? selectInputTouched : errors?.livingSituation?.message ? selectInputError : selectInputNotTouched} className="text-center w-1/2 block bg-transparent border-1 rounded-full without-ring " placeholder="Select" {...register("atJobYears", { required: "Please select an option" })} >
                    <option hidden disabled value=''>Year</option>
                    <option value="0">0 Years</option>
                    <option value="1">1 Years</option>
                    <option value="2">2 Years</option>
                    <option value="3">3 Years</option>
                    <option value="4">4 Years</option>
                    <option value="5">5 Years</option>
                    <option value="6">6 Years</option>
                    <option value="7">7 Years</option>
                    <option value="8">8 Years</option>
                    <option value="9">9 Years</option>
                    <option value="10">10 Years</option>
                    <option value="11">11 Years</option>
                    <option value="12">12 Years</option>
                    <option value="13">13 Years</option>
                    <option value="14">14 Years</option>
                    <option value="15">15 Years</option>
                    <option value="16">16 Years</option>
                    <option value="17">17 Years</option>
                    <option value="18">18 Years</option>
                    <option value="19">19 Years</option>
                    <option value="20">20 Years</option>
                    <option value="21">21 Years</option>
                    <option value="22">22 Years</option>
                    <option value="23">23 Years</option>
                    <option value="24">24 Years</option>
                    <option value="25">25 Years</option>
                    <option value="26">26 Years</option>
                    <option value="27">27 Years</option>
                    <option value="28">28 Years</option>
                    <option value="29">29 Years</option>
                    <option value="30">30 Years</option>
                </select>
                
                <select required defaultValue={''} id="atJobMonths" style={isTouched(atJobMonths)? selectInputTouched : errors?.livingSituation?.message ? selectInputError : selectInputNotTouched} className="text-center block w-1/2 bg-transparent border-1 rounded-full without-ring "  placeholder="Select" {...register("atJobMonths", { required: "Please select an option" })} >
                    <option hidden disabled value=''>Month</option>
                    <option value="0">0 Months</option>
                    <option value="1">1 Months</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>
                    <option value="7">7 Months</option>
                    <option value="8">8 Months</option>
                    <option value="9">9 Months</option>
                    <option value="10">10 Months</option>
                    <option value="11">11 Months</option>
                </select>
                </div>
                <ErrorMessage errors={errors} name="atJobYears" render={({ message }) => <p style={errorText}>{message}</p>} />
                <ErrorMessage errors={errors} name="atJobMonths" render={({ message }) => {
                    console.log(atJobYears, atJobMonths)
                    if (atJobYears) {
                    return (
                    <p style={errorText}>{message}</p>
                    )} else {
                    return null
                    }
                }} />
                </div>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="incomeAfterTax">Monthly income (after tax)</label>
                <input id="incomeAfterTax" max={999999} min={0}  onWheel={(e) => (e.target as HTMLElement).blur()} inputMode="numeric" style={isTouched(incomeAfterTax) ? inputTouched : errors?.incomeAfterTax?.message ? inputError : inputNotTouched} pattern="[0-9]*" className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="number" placeholder="" {...register("incomeAfterTax", { required: "Please enter an amount", maxLength: 20 })} />            
                <ErrorMessage errors={errors} name="incomeAfterTax" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
            </>
        )   
    }

    function isEmploymentTypeBusinessOwner() {
        return (
            <>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="Business Name">Business name</label>
                    <input id="businessName" maxLength={200} style={isTouched(businessName) ? inputTouched : errors?.businessName?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="text" placeholder="" {...register("businessName", { required: "Please enter your business name", maxLength: 200 })} />
                    <ErrorMessage errors={errors} name="businessName" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="Business Structure">Business structure</label>
                    <select required defaultValue={''} id="businessStructure" style={isTouched(businessStructure)? selectInputTouched : errors?.businessStructure?.message ? selectInputError : selectInputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Business Structure" {...register("businessStructure", { required: "Please select an option"})} >
                        <option hidden disabled value=''>Select</option>
                        <option value="Company">Company</option>
                        <option value="Sole Trader">Sole Trader</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Trust">Trust</option>
                    </select>
                    <ErrorMessage errors={errors} name="businessStructure" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="ABN Registration period">ABN registration period</label>
                    <select required defaultValue={''} id="abnRegistrationPeriod" style={isTouched(abnRegistrationPeriod)? selectInputTouched : errors?.abnRegistrationPeriod?.message ? selectInputError : selectInputNotTouched} className="text-center w-full block bg-transparent border-1 rounded-full without-ring " placeholder="Select" {...register("abnRegistrationPeriod", { required: "Please select an option"})} >
                        <option  hidden disabled value=''>Select</option>
                        <option value="Less Than 1">Less than a year</option>
                        <option value="1">1 Year</option>
                        <option value="2">2 Years</option>
                        <option value="3-4">3-4 Years</option>
                        <option value="5+">5+ Years</option>
                    </select>
                    <ErrorMessage errors={errors} name="abnRegistrationPeriod" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="GSTRegisteredYes">Is business GST registered?</label>
                    <div className="flex flex-row justify-between grow justify-items-stretch w-full" style={{gap:"16px"}}>
                    <button id="GSTRegisteredYes" onClick={handleGSTClick} className={hasClickedGST ? (gstRegistered ? buttonActive : buttonNotActive) : (hasClickedSubmit ? buttonNotPressedError : buttonNotActive)}>
                                Yes
                    </button>
                    <button id="GSTRegisteredNo" onClick={handleGSTClick} className={hasClickedGST ? (gstRegistered ? buttonNotActive : buttonActive) : (hasClickedSubmit ? buttonNotPressedError : buttonNotActive)}>
                                No
                    </button>
                    
                    </div>
                    { !hasClickedGST && hasClickedSubmit ? <p style={errorText}>Please select an option</p> : null }
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="incomeAfterTax">Monthly income (after tax)</label>
                    <input id="incomeAfterTax" min={0} max={999999999} onWheel={(e) => (e.target as HTMLElement).blur()} inputMode="numeric" style={isTouched(incomeAfterTax) ? inputTouched : errors?.incomeAfterTax?.message ? inputError : inputNotTouched} pattern="[0-9]*" className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="number" placeholder="" {...register("incomeAfterTax", { required: "Please enter an amount", maxLength: 80 })} />            
                    <ErrorMessage errors={errors} name="incomeAfterTax" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
            </>
        )
    }

    function isEmploymentTypeUnemployed() {
        return (
            <>
            <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
            <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="incomeAfterTax">Monthly income (after tax)</label>
            <input id="incomeAfterTax" min={0} max={999999} onWheel={(e) => (e.target as HTMLElement).blur()} inputMode="numeric" style={isTouched(incomeAfterTax) ? inputTouched : errors?.incomeAfterTax?.message ? inputError : inputNotTouched} pattern="[0-9]*" className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="number" placeholder="" {...register("incomeAfterTax", { required: "Please enter an amount", maxLength: 80 })} />            
            <ErrorMessage errors={errors} name="incomeAfterTax" render={({ message }) => <p style={errorText}>{message}</p>} />
            </div> 
            </>
        )
    }
 if (!formSubmitted) {
    datadogRum.startView(
        {
            name: 'Grays Express Form',
        }
    )
    return (
        <>
        {isLoading
        ? <RouletteSpinnerOverlay loading={isLoading}　color="#ca113c" />
        : null
      }
        <div className="flex flex-col items-center max-w-md lg:w-1/3 w-full mx-auto" style={{ gap: "24px", padding: "48px 20px"}}>
            <div className="flex flex-col items-center w-full" style={{gap: "8px"}}>
                <h1 style={{fontWeight: "600", fontSize: "20px", lineHeight: "27px"}} className="text-center font-avenir">Express Application</h1>
                <p style={{fontWeight:"400", lineHeight:"19px", fontSize: "14px"}} className="text-center font-avenir">Applying for a Loan</p>
            </div>

        </div>
            <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-center max-w-md lg:w-1/3 w-full px-5 lg:px-0 mx-auto" style={{ gap: "24px"}}>
            <div className="flex flex-col w-full items-start" style={{ gap: "24px", padding:"0"}}>
                <h2 style={sectionTitle} className="font-avenir">Personal details</h2>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={label}className="block" htmlFor="firstName">First name</label>
                <input id="firstName" maxLength={20} style={ isTouched(firstName) ? inputTouched : errors?.firstName?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 border-grey-200 rounded-full without-ring" type="text" placeholder="" {...register("firstName", { required: "Please enter your first name"})} />
                <ErrorMessage errors={errors} name="firstName" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>

                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={label}className="block" htmlFor="lastName">Last name</label>
                <input id="lastName" maxLength={20} style={ isTouched(lastName) ? inputTouched : errors?.lastName?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring" type="text" placeholder="" {...register("lastName", { required: "Please enter your last name"})} />
                <ErrorMessage errors={errors} name="lastName" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>

                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={label}className="block" htmlFor="email">Email</label>
                <input id="email" style={isTouched(email)? inputTouched : errors?.email?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring" type="email" placeholder="" {...register("email", { required: "Email is required", pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "That doesn’t look right. Please enter your email address in the correct format."
                } })} />
                <ErrorMessage errors={errors} name="email" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={label}className="block" htmlFor="phoneNumber">Mobile</label>
                <input id="phoneNumber" pattern="^04[0-9]{8}" maxLength={10} onWheel={(e) => (e.target as HTMLElement).blur()}  inputMode="numeric" style={ isTouched(phoneNumber) ? inputTouched : errors?.phoneNumber?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="number" placeholder="04xx xxx xxx" {...register("phoneNumber", { required: "Please enter your Australian mobile number", maxLength:10, pattern: {
                    value: /^04[0-9]{8}/,
                    message: "Entered Australian mobile number format is incorrect. Re-enter it and try again"
                }   })} />
                <ErrorMessage errors={errors} name="phoneNumber" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={label}className="block" htmlFor="firstName">Residency status</label>
                <select required defaultValue={''} id="residentialStatus" style={isTouched(residentialStatus)? selectInputTouched : errors?.residentialStatus?.message ? selectInputError : selectInputNotTouched} className="caret-graysPrimaryRed text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Select" {...register("residentialStatus", { required: "Please select an option" })} >
                    <option disabled hidden value=''>Select</option>
                    <option value="Australian Citizen">Australian Citizen</option>
                    <option value="Permanent Resident">Permanent Resident</option>
                    <option value="Visa 457">Visa 457</option>
                    <option value="Visa Other">Visa Other</option>
                    <option value="Visa Unspecified">Visa Unspecified</option>
                    <option value="Non Resident">Non Resident</option>
                </select>
                <ErrorMessage errors={errors} name="residentialStatus" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                <hr style={{backgroundColor: "#F2F2F2"}} className="h-px w-full"/>
            </div>
            <div className="flex flex-col items-start w-full" style={{ gap: "24px"}}>
                <h2 style={sectionTitle}  className="font-avenir">Residential details</h2>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="livingSituation">Living situation</label>
                <select required defaultValue={''} id="livingSituation" style={isTouched(livingSituation)? selectInputTouched : errors?.livingSituation?.message ? selectInputError : selectInputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Select" {...register("livingSituation", { required: "Please select an option" })} >
                    <option disabled hidden value=''>Select</option>
                    <option value="I Own The Home I Live In (With Mortgage)">I Own The Home I Live In (With Mortgage)</option>
                    <option value="I Own The Home I Live In (Without Mortgage)">I Own The Home I Live In (Without Mortgage)</option>
                    <option value="I'm A Tenant (But I Have A Mortgage On Another Property)">I'm A Tenant (But I Have A Mortgage On Another Property)</option>
                    <option value="I'm A Tenant (Renting)">I'm A Tenant (Renting)</option>
                    <option value="I'm A Boarder">I'm A Boarder</option>
                    <option value="I'm Living With Parents">I'm Living With Parents</option>
                </select>
                <ErrorMessage errors={errors} name="livingSituation" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="address">Address</label>
                {/* <input id="address" style={{fontSize: "14px"}} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " type="text" placeholder="Please type your address" {...register("address", { required: true})} /> */}
                <Controller
                control={control}
                name="address"
                render={({ field: { onChange } }) => (
                    <AutoComplete
                    style={isTouched(state) ? addressInputTouched : errors?.address?.message ? addressInputError : addressInputNotTouched} className="text-center block w-full bg-transparent border rounded-full without-ring"
                    options={{
                        types: ["address"],
                        componentRestrictions: {country: "au"}
                    }}
                    placeholder="Please enter your address"
                    // defaultValue={''}
                    //    apiKey={"AIzaSyBisI_KIlB4-HvGBjRm47UXhDSUz6xCN6g"}
                    apiKey={"AIzaSyBh6HY1M7tb3CFoYib2HOHPBMNSWntggTY"}
                    onPlaceSelected={(place) => {
                        setSubPremise(place.address_components.find((s: { types: string[]; }) => s.types.includes("subpremise")) ? place.address_components.find((s: { types: string[]; }) => s.types.includes("subpremise")).long_name : "")
                        setStreetName(place.address_components.find((s: { types: string[]; }) => s.types.includes("route")).long_name)
                        setStreetNumber(place.address_components.find((s: { types: string[]; }) => s.types.includes("street_number")) ? place.address_components.find((s: { types: string[]; }) => s.types.includes("street_number")).long_name : "")
                        setCity(place.address_components.find((s: { types: string[]; }) => s.types.includes("locality")).long_name)
                        setPostcode(place.address_components.find((s: { types: string[]; }) => s.types.includes("postal_code")).short_name)
                        setState(place.address_components.find((s: { types: string[]; }) => s.types.includes("administrative_area_level_1")).short_name)
                        setCountry(place.address_components.find((s: { types: string[]; }) => s.types.includes("country")).long_name)
                        onChange(place.formatted_address)
                    }}
                    />
                )}
                rules={{ required: "Please enter your address" }}
                />
                <ErrorMessage errors={errors} name="address" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="Years At Address">Time at current address</label>
                <div className="flex flex-row justify-between justify-items-stretch w-full" style={{gap:"16px"}}>
                {/* <div className="flex-col items-start" style={{gap: "0px"}}> */}
                <select required defaultValue={''} id="atAddressYears" style={isTouched(atAddressYears)? selectInputTouched : errors?.atAddressYears?.message ? selectInputError : selectInputNotTouched} className="text-center w-1/2 block bg-transparent border-1 rounded-full without-ring " placeholder="Select" {...register("atAddressYears", { required: "Please select an option"})} >
                    <option disabled hidden value=''>Years</option>
                    <option value="0">0 Years</option>
                    <option value="1">1 Years</option>
                    <option value="2">2 Years</option>
                    <option value="3">3 Years</option>
                    <option value="4">4 Years</option>
                    <option value="5">5 Years</option>
                    <option value="6">6 Years</option>
                    <option value="7">7 Years</option>
                    <option value="8">8 Years</option>
                    <option value="9">9 Years</option>
                    <option value="10">10 Years</option>
                    <option value="11">11 Years</option>
                    <option value="12">12 Years</option>
                    <option value="13">13 Years</option>
                    <option value="14">14 Years</option>
                    <option value="15">15 Years</option>
                    <option value="16">16 Years</option>
                    <option value="17">17 Years</option>
                    <option value="18">18 Years</option>
                    <option value="19">19 Years</option>
                    <option value="20">20 Years</option>
                    <option value="21">21 Years</option>
                    <option value="22">22 Years</option>
                    <option value="23">23 Years</option>
                    <option value="24">24 Years</option>
                    <option value="25">25 Years</option>
                    <option value="26">26 Years</option>
                    <option value="27">27 Years</option>
                    <option value="28">28 Years</option>
                    <option value="29">29 Years</option>
                    <option value="30">30 Years</option>
                </select>
                <select required defaultValue={''} id="atAddressMonths" style={isTouched(atAddressMonths)? selectInputTouched : errors?.atAddressMonths?.message ? selectInputError : selectInputNotTouched} className="text-center block w-1/2 bg-transparent border-1 rounded-full without-ring "  placeholder="Select" {...register("atAddressMonths", { required: "Please select an option"})} >
                    <option disabled hidden value=''>Months</option>
                    <option value="0">0 Months</option>
                    <option value="1">1 Months</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>
                    <option value="7">7 Months</option>
                    <option value="8">8 Months</option>
                    <option value="9">9 Months</option>
                    <option value="10">10 Months</option>
                    <option value="11">11 Months</option>
                </select>
                </div>
                <ErrorMessage errors={errors} name="atAddressYears" render={({ message }) => <p style={errorText}>{message}</p>} />
                <ErrorMessage errors={errors} name="atAddressMonths" render={({ message }) => {
                    if (atAddressYears !== "") {
                    return (
                    <p style={errorText}>{message}</p>
                    )} else {
                    return null
                    }
                }} />
                </div>
                <hr style={{backgroundColor: "#F2F2F2"}} className="h-px w-full"/>
            </div>
            <div className="flex flex-col items-start w-full" style={{ gap: "24px"}}>
                <h2 style={sectionTitle} className="font-avenir">Employment details</h2>
                <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block font-avenir" htmlFor="lastName">Employment type</label>
                <select required defaultValue={''} id="employmentType" style={isTouched(employmentTypeCheck)? selectInputTouched : errors?.livingSituation?.message ? selectInputError : selectInputNotTouched} className="text-center font-avenir block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Employment Type" {...register("employmentType", { required: "Please select an option"})} >
                    <option disabled hidden value=''>Select</option>
                    <option value="Employee">Employee</option>
                    <option value="Business Owner Or Self Employed">I am a business owner / I have an ABN</option>
                    <option value="Unemployed">Unemployed</option>
                </select>

                <ErrorMessage errors={errors} name="employmentType" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div>
                {employmentTypeCheck === "Employee" ? isEmploymentTypeEmployee() : null }
                {employmentTypeCheck === "Business Owner Or Self Employed" ? isEmploymentTypeBusinessOwner() : null }
                {employmentTypeCheck === "Unemployed" ? isEmploymentTypeUnemployed() : null }
                <hr style={{backgroundColor: "#F2F2F2"}} className="h-px w-full"/>
            </div>
            
            <div className="flex flex-col w-full">
                    
                    <ErrorMessage errors={errors} name="acceptTerms" render={({ message }) => <p style={checkboxErrorText}>{message}</p>} />
                    <div className="flex w-full mt-1 ">
                        <input type="checkbox" className="w-5 h-5 text-graysPrimaryBlue border-graysPrimaryBlue border-2 mr-2 rounded without-ring" placeholder="acceptTerms" {...register("acceptTerms", {required: "To continue you need to agree with the CarClarity privacy policy, credit guide, privacy consent and electronic document delivery consent"})} />
                        <p className="pl-2 font-avenir" style={{fontSize: "12px", color: "#303030"}} >I confirm that I have read and accepted CarClarity <a target="_blank" href="https://www.carclarity.com.au/privacy-policy" style={{textDecoration: "underline", color: "#004E93"}} rel="noreferrer">privacy policy</a>, <a target="_blank" href="https://carclarity.com.au/credit-guide" style={{textDecoration: "underline", color: "#004E93"}} rel="noreferrer">credit guide</a>, <a target="_blank" href="https://carclarity.com.au/privacy-act-consent-electronic-delivery" style={{textDecoration: "underline", color: "#004E93"}} rel="noreferrer">privacy consent</a> and <a target="_blank" href="https://carclarity.com.au/electronic-document-delivery-consent" style={{textDecoration: "underline", color: "#004E93"}} rel="noreferrer">electronic document delivery consent</a></p>
                        
                    </div>
                    <ErrorMessage errors={errors} name="acceptTerms" render={({ message }) => <p style={checkboxErrorText}>{message}</p>} />
                    <div className="flex w-full mt-8 pb-4 justify-center align-middle items-center">
                        <button onClick={handleSubmit(onSubmit, onError)} className="text-white bg-graysPrimaryRed font-normal font-avenir text-sm px-9 py-3 rounded-full">
                            Submit
                        </button>
                    </div>
                    <div className="flex w-full mt-8 pb-4 justify-center align-middle items-center">
                        <p className="pl-2 font-avenir" style={{fontSize: "12px", color: "#BDBDBD"}} >
                            <strong>You are now on CarClarity's webpage. CarClarity is a preferred partner of Grays for the provision of finance.</strong> <br></br><br></br>
                            Disclaimer: By clicking submit, you understand that personal information collected on this form will be, (i) shared with CarClarity ACL Pty Ltd ABN 50 606 243 243 (CarClarity) (who offer loan products), and (ii) used to apply for and seek finance from CarClarity.<br></br>
<a target="_blank" href="https://www.carclarity.com.au/privacy-policy" style={{textDecoration: "underline", color: "#004E93"}} rel="noreferrer">Our Privacy Policy</a> contains information about how we handle your personal information, your right to access and update it and how to make a complaint. Information provided to CarClarity (including personal information collected on this application form) will be handled according to CarClarity’s Privacy Policy.
You agree that your use of Car Clarity’s website and services are subject to CarClarity’s website terms located at <a target="_blank" href="https://www.carclarity.com.au/terms-conditions" style={{textDecoration: "underline", color: "#004E93"}} rel="noreferrer">https://www.carclarity.com.au/terms-conditions</a><br></br>
As a referrer, Grays eCommerce Group Limited ABN 94 125 736 914 (or one of its Related Bodies Corporate) will receive a benefit if you choose to settle a loan product with CarClarity. <br></br><br></br>
This webpage belongs to CarClarity. No information provided to CarClarity is provided to, retained or held by Grays. 
                        </p>
                    </div>

            </div>
            </div>
</form>

            </FormProvider>
            
            {formSubmittedError ? showApiErrorToast() : null}
        
        </>
    )
                } else {
                    window.scrollTo(0, 0)
                 return (
                    <>
                    <div className="flex flex-col items-center lg:w-1/3 w-full mx-auto" style={{ gap: "24px", padding:"48px 20px"}}>
                        <div className="flex flex-col items-center w-full" style={{gap: "40px", padding: "0px"}}>
                            
                            <span className="bg-graysPrimaryRed text-white text-sm font-semibold inline-flex items-center rounded-full w-24 h-24">
                                <svg className="w-24 h-24" viewBox="-4 -32 40 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0496 25.5903C11.6591 25.9809 11.0259 25.9809 10.6354 25.5903L1.17097 16.1259C0.78045 15.7354 0.78045 15.1022 1.17097 14.7117L4.59337 11.2893C4.98428 10.8984 5.61822 10.8988 6.00858 11.2903L10.635 15.9299C11.0255 16.3215 11.6597 16.3218 12.0506 15.9305L27.555 0.410396C27.9455 0.019535 28.579 0.0193758 28.9696 0.41004L32.3927 3.83308C32.7832 4.2236 32.7832 4.85676 32.3927 5.24729L12.0496 25.5903Z" fill="white"/>
                                </svg>


                            </span>
                            <div className="flex flex-col items-center w-full" style={{gap: "16px", padding: "0px"}}>
                                <h1 style={{fontWeight:"600"}} className="text-lg text-center font-avenir">Thank you!</h1>
                                <p className="text-sm text-center font-light font-Montserrat">Your enquiry has been submitted. <br></br> One of our loan experts will contact you shortly.</p>
                            </div>
                            
                        </div>
                    </div>
                    </>
                 )
                }
}

export default ExpressForm